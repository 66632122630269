import Big from 'big.js';
import {
  Button,
  Buttons,
  CancelButton,
  Checkbox,
  Currency,
  DateTime,
  DeleteConfirmation,
  Field,
  Form,
  HelpTooltip,
  Icon,
  Level,
  ModalCard,
  Table,
  Tooltip,
} from '~/components';
import { TableBoxRowActions } from '~/components/table';
import { useApi, useConfirmation, useWorkspace } from '~/contexts';
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { colors, weights } from '~/styles';
import { dateFormats, emptyStringToNull, mergeValues } from '~/utils';
import * as Yup from 'yup';
import { Header } from './Header';
import { TableTitle } from './TableTitle';
import styled from 'styled-components';

const InvoicedIcon = styled(Icon)`
  color: ${colors.primary};
`;

const ContainerControl = styled.span`
  display: flex;
  align-items: center;
`;

function OtherItemsTable({ projectModel, onChange }) {
  const [editIndex, setEditIndex] = useState(null);

  const handleSave = (value) => {
    value = emptyStringToNull(value);
    if (!value.fee) {
      value.fee = 0;
    }

    const otherItems = value.id
      ? projectModel.otherItems.map((oi) =>
          oi.id === value.id ? { ...oi, ...value, invoiceItemId: value.invoiceItem?.id } : oi,
        )
      : [...projectModel.otherItems, { ...value, id: _.uniqueId('oi_'), invoiceItemId: value.invoiceItem?.id }];

    setEditIndex(null);
    onChange(otherItems);
  };

  const handleDelete = (item) => {
    onChange(projectModel.otherItems.filter((r) => r.id !== item.id));
  };

  const totals = {
    fee: projectModel.otherItems.reduce((a, v) => a + v.fee, 0),
  };

  const confirmation = useConfirmation();
  const currency = projectModel.currency;

  const handleAddMultiple = async () => {
    await confirmation.prompt((resolve) => {
      const handleSubmit = (values) => {
        const otherItems = [...projectModel.otherItems, ...values.map((oi) => ({ ...oi, id: _.uniqueId('oi_') }))];
        onChange(otherItems);
        resolve(true);
      };

      return (
        <MultipleOtherItemsForm
          project={projectModel}
          currency={currency}
          onSubmit={handleSubmit}
          onCancel={() => resolve(false)}
        />
      );
    });
  };

  return (
    <>
      <Header>
        <Level>
          <Level.Item>
            <TableTitle>
              Other Items to Bill
              <HelpTooltip
                message="Schedule the invoicing of miscellaneous items below. These items could include products, licenses, deliverables, or materials."
                style={{ marginLeft: '0.5rem' }}
              />
            </TableTitle>
          </Level.Item>

          <Level.Item right narrow>
            <Button isOutline onClick={handleAddMultiple}>
              Add Multiple
            </Button>
          </Level.Item>
        </Level>
      </Header>

      <Form.Control>
        <Table small>
          <Table.BoxHeader>
            <Table.Column width="7rem">Bill Date</Table.Column>
            <Table.Column width="10rem">Invoice Item</Table.Column>
            <Table.Column>Description</Table.Column>
            <Table.Column align="right" width="7rem">
              Amount
            </Table.Column>
            <Table.Column width="2rem" />
            <Table.BoxActionsColumn />
          </Table.BoxHeader>
          <Table.Body>
            {projectModel.otherItems.map((item, index) => (
              <OtherItemRowDetails
                key={item.id}
                currency={currency}
                otherItem={item}
                disableActions={editIndex !== null}
                onEdit={() => setEditIndex(index)}
                onDelete={() => handleDelete(item)}
              />
            ))}
            <Table.Row>
              <Table.Cell>
                <Button isAnchor isStrong onClick={() => setEditIndex(-1)}>
                  <Icon icon="plus" size="xs" spaceRight />
                  Quick Add
                </Button>
              </Table.Cell>
            </Table.Row>
            <Table.Row style={{ borderBottom: 'none', fontWeight: weights.bold, textTransform: 'uppercase' }}>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell />
              <Table.Cell />
              <Table.Cell>
                <Currency value={totals.fee} currency={currency} />
              </Table.Cell>
              <Table.Cell />
              <Table.Cell />
            </Table.Row>
          </Table.Body>
        </Table>
      </Form.Control>

      {editIndex != null && (
        <OtherItemsModalForm
          otherItem={editIndex >= 0 ? projectModel.otherItems[editIndex] : null}
          project={projectModel}
          currency={currency}
          onSubmit={handleSave}
          onCancel={() => setEditIndex(null)}
        />
      )}
    </>
  );
}

function OtherItemRowDetails({
  currency,
  otherItem: { date, invoiceItem, description, fee, invoice },
  disableActions,
  onEdit,
  onDelete,
}) {
  const confirmation = useConfirmation();

  const isInvoiced = invoice && invoice.statusId !== 'draft';
  const areActionsDisabled = isInvoiced || disableActions;
  const tooltip = areActionsDisabled ? "Invoiced items can't be modified." : undefined;

  const handleDelete = async () => {
    const confirm = await confirmation.prompt((resolve) => (
      <DeleteConfirmation resolve={resolve}>Are you sure you want to delete this item?</DeleteConfirmation>
    ));
    if (!confirm) {
      return;
    }
    onDelete();
  };

  return (
    <Table.BoxRow>
      <Table.Cell>
        <DateTime value={date} />
      </Table.Cell>
      <Table.Cell>{invoiceItem.name}</Table.Cell>
      <Table.Cell>{description}</Table.Cell>
      <Table.Cell>
        <Currency value={fee} currency={currency} />
      </Table.Cell>
      <Table.Cell style={{ padding: 3 }}>
        {isInvoiced && (
          <Tooltip message="An invoice has been published for this other item.">
            <InvoicedIcon icon="file-invoice-dollar" />
          </Tooltip>
        )}
      </Table.Cell>
      <TableBoxRowActions>
        <TableBoxRowActions.Edit disabled={areActionsDisabled} tooltip={tooltip} onClick={onEdit} />
        <hr />
        <TableBoxRowActions.Delete disabled={areActionsDisabled} tooltip={tooltip} onClick={handleDelete} />
      </TableBoxRowActions>
    </Table.BoxRow>
  );
}

function OtherItemsModalForm({ currency, otherItem, project, onSubmit, onCancel }) {
  const isNew = otherItem === null;

  const { workspace } = useWorkspace();
  const api = useApi();

  const [defaultInvoiceItem, setDefaultInvoiceItem] = useState();

  useEffect(() => {
    (async () => {
      if (!isNew) return;

      try {
        const { data } = await api.www
          .workspaces(workspace.id)
          .projects(project.id)
          .defaultInvoiceItem({ projectTypeId: project.projectType ? project.projectType.id : undefined });

        setDefaultInvoiceItem(data);
      } catch {
        // Do nothing
      }
    })();
  }, [api, workspace.id, project, isNew]);

  const initialValues = mergeValues(
    {
      id: null,
      date: moment().format(dateFormats.isoDate),
      invoiceItem: null,
      description: '',
      quantity: '',
      rate: '',
      fee: '',
    },
    { invoiceItem: defaultInvoiceItem, ...otherItem },
  );

  const [includeQuantity, setIncludeQuantity] = useState(() => otherItem?.quantity > 0);

  if (!defaultInvoiceItem && isNew) return;

  return (
    <ModalCard title={isNew ? 'Add Item' : 'Edit Item'} onClose={onCancel}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          date: Yup.date().label('Bill Date').nullable().required(),
          invoiceItem: Yup.mixed().label('Invoice Item').required(),
          description: Yup.string().label('Description').max(255).required(),
          quantity: Yup.number()
            .label('Quantity')
            .positive()
            .max(99999999.99)
            .nullable()
            .when('$includeQuantity', (_, schema) => (includeQuantity ? schema.required() : schema)),
          rate: Yup.number()
            .label('Rate')
            .min(-99999999999)
            .max(99999999999)
            .nullable()
            .when('$includeQuantity', (_, schema) => (includeQuantity ? schema.required() : schema)),
          fee: Yup.number().label('Amount').min(-99999999999).max(99999999999).required(),
        })}>
        {({ submitForm, ...formik }) => {
          const handleIncludeQuantityChange = ({ target: { checked } }) => {
            formik.setValues({ ...formik.values, quantity: '', rate: '', fee: '' });
            setIncludeQuantity(checked);
          };

          const handleCalculateFee = () => {
            const quantity = _.isNumber(formik.values.quantity)
              ? _.round(formik.values.quantity, 2)
              : formik.values.quantity;

            const rate = _.isNumber(formik.values.rate) ? _.round(formik.values.rate, 7) : formik.values.rate;

            const fee =
              _.isNumber(formik.values.quantity) && _.isNumber(formik.values.rate)
                ? Big(quantity).times(rate).round(2).toNumber()
                : '';

            formik.setValues({ ...formik.values, fee, quantity, rate });
          };

          return (
            <>
              <ModalCard.Body>
                <Form.Control>
                  <Checkbox
                    label="Include a quantity and rate for this item"
                    checked={includeQuantity}
                    onChange={handleIncludeQuantityChange}
                  />
                </Form.Control>
                <Form.Control>
                  <Field.DayPicker name="date" placeholder="Bill Date" clearable={false} />
                  <Field.InvoiceItemSelect
                    name="invoiceItem"
                    placeholder="Invoice Item"
                    type="income"
                    position="top"
                    clearable={false}
                  />
                </Form.Control>
                <Form.Control>
                  <Field.Text name="description" placeholder="Description" maxLength={255} />
                </Form.Control>

                {includeQuantity && (
                  <Form.Control>
                    <Field.Number name="quantity" placeholder="Quantity" precision={2} onBlur={handleCalculateFee} />
                    <Field.Currency
                      name="rate"
                      placeholder="Rate"
                      precision={7}
                      currency={currency}
                      onBlur={handleCalculateFee}
                    />
                  </Form.Control>
                )}

                <Form.Control>
                  <Field.Currency name="fee" placeholder="Amount" disabled={includeQuantity} currency={currency} />
                </Form.Control>
              </ModalCard.Body>
              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onCancel}>Cancel</CancelButton>
                  <Button onClick={submitForm}>{isNew ? 'Add' : 'Save'}</Button>
                </Buttons>
              </ModalCard.Footer>
            </>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

function MultipleOtherItemsForm({ currency, project, onSubmit, onCancel }) {
  const { workspace } = useWorkspace();
  const api = useApi();

  const [defaultInvoiceItem, setDefaultInvoiceItem] = useState();

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.www
          .workspaces(workspace.id)
          .projects(project.id)
          .defaultInvoiceItem({ projectTypeId: project.projectType ? project.projectType.id : undefined });

        setDefaultInvoiceItem(data);
      } catch {
        // Do nothing
      }
    })();
  }, [api, workspace.id, project]);

  const initialValues = {
    start: null,
    end: null,
    dayOfMonth: null,
    invoiceItem: defaultInvoiceItem,
    description: '',
    quantity: '',
    rate: '',
    fee: '',
  };

  const [includeQuantity, setIncludeQuantity] = useState(false);

  const handleSubmit = async (values) => {
    const { start, end, dayOfMonth, invoiceItem, description, quantity, rate, fee } = emptyStringToNull(values);

    const { data: dates } = await api.www
      .workspaces(workspace.id)
      .projects(project.id)
      .generateRecurringDates({ start, end, dayOfMonth });

    const instances = dates.map((date) => {
      return {
        date: moment(date).format(dateFormats.isoDate),
        invoiceItemId: invoiceItem?.id,
        invoiceItem,
        description,
        quantity,
        rate,
        fee,
      };
    });

    onSubmit(instances);
  };

  if (!defaultInvoiceItem) return;

  return (
    <ModalCard title="Add Multiple Other Items" onClose={onCancel}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={Yup.object().shape({
          start: Yup.date().label('Starting Month').nullable().required(),
          end: Yup.date()
            .label('Ending Month')
            .nullable()
            .min(Yup.ref('start'), 'Ending Month must be after Starting Month')
            .required(),
          dayOfMonth: Yup.mixed()
            .label('Day of Month')
            .oneOf([...Array(28).keys()].map((i) => i + 1).concat('last'))
            .required(),
          invoiceItem: Yup.mixed().label('Invoice Item').required(),
          description: Yup.string().label('Description').max(255).required(),
          quantity: Yup.number()
            .label('Quantity')
            .positive()
            .max(99999999.99)
            .nullable()
            .when('$includeQuantity', (_, schema) => (includeQuantity ? schema.required() : schema)),
          rate: Yup.number()
            .label('Rate')
            .min(-99999999999)
            .max(99999999999)
            .nullable()
            .when('$includeQuantity', (_, schema) => (includeQuantity ? schema.required() : schema)),
          fee: Yup.number().label('Amount').min(-99999999999).max(99999999999).required(),
        })}>
        {({ submitForm, ...formik }) => {
          const handleIncludeQuantityChange = ({ target: { checked } }) => {
            formik.setValues({ ...formik.values, quantity: '', rate: '', fee: '' });
            setIncludeQuantity(checked);
          };

          const handleCalculateFee = () => {
            const quantity = _.isNumber(formik.values.quantity)
              ? _.round(formik.values.quantity, 2)
              : formik.values.quantity;

            const rate = _.isNumber(formik.values.rate) ? _.round(formik.values.rate, 7) : formik.values.rate;

            const fee =
              _.isNumber(formik.values.quantity) && _.isNumber(formik.values.rate)
                ? Big(quantity).times(rate).round(2).toNumber()
                : '';

            formik.setValues({ ...formik.values, fee, quantity, rate });
          };

          return (
            <>
              <ModalCard.Body>
                <Form.Control>
                  <Checkbox
                    label="Include a quantity and rate for these items"
                    checked={includeQuantity}
                    onChange={handleIncludeQuantityChange}
                  />
                </Form.Control>

                <Form.Control>
                  <Field.DayPicker
                    name="start"
                    placeholder="Starting Month"
                    locale="en-US"
                    displayFormat={dateFormats.monthYear}
                    scope="month"
                  />
                  <Field.DayPicker
                    name="end"
                    placeholder="Ending Month"
                    locale="en-US"
                    displayFormat={dateFormats.monthYear}
                    scope="month"
                  />
                </Form.Control>

                <Form.Control>
                  <ContainerControl style={{ marginTop: '0' }}>
                    Scheduled on the
                    <div style={{ margin: '0 0.5rem' }}>
                      <Field.SingleSelect
                        name="dayOfMonth"
                        style={{ width: '7rem', margin: '0 0.5rem', textAlign: 'right' }}
                        materialPlaceholder={false}>
                        {[...Array(28).keys()].map((day) => (
                          <option key={day} value={day + 1}>
                            {day + 1}
                            {{
                              1: 'st',
                              2: 'nd',
                              3: 'rd',
                            }[day + 1] || 'th'}
                          </option>
                        ))}
                        <option value="last">Last</option>
                      </Field.SingleSelect>
                    </div>
                    <span>day of each month</span>
                  </ContainerControl>
                </Form.Control>

                <Form.Control>
                  <Field.InvoiceItemSelect
                    name="invoiceItem"
                    placeholder="Invoice Item"
                    type="income"
                    position="top"
                    clearable={false}
                  />
                </Form.Control>

                <Form.Control>
                  <Field.Text name="description" placeholder="Description" maxLength={255} />
                </Form.Control>

                {includeQuantity && (
                  <Form.Control>
                    <Field.Number name="quantity" placeholder="Quantity" precision={2} onBlur={handleCalculateFee} />
                    <Field.Currency
                      name="rate"
                      placeholder="Rate"
                      precision={7}
                      currency={currency}
                      onBlur={handleCalculateFee}
                    />
                  </Form.Control>
                )}

                <Form.Control>
                  <Field.Currency name="fee" placeholder="Amount" disabled={includeQuantity} currency={currency} />
                </Form.Control>
              </ModalCard.Body>
              <ModalCard.Footer>
                <Buttons align="right">
                  <CancelButton onClick={onCancel}>Cancel</CancelButton>
                  <Button onClick={submitForm}>Add</Button>
                </Buttons>
              </ModalCard.Footer>
            </>
          );
        }}
      </Formik>
    </ModalCard>
  );
}

export default OtherItemsTable;
